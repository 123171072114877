// Variables
$primary-color: #26A65B;
$secondary-color: #7117E5;
$light-bg: #F0F2FB;
$lighter-bg: #E8ECFF;
$border-light: #E0E0E0;
$border-light-alpha: #DDE3FFAD;
$text-dark: #062324;
$text-muted: #6c757d;
$text-secondary: #485C5C;
$success-color: #28a745;
$danger-color: #dc3545;
$info-color: #007bff;
$disabled-color: #ccc;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin button-base {
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}

.show-results-button {
  flex: 1;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; 
  border: solid 1px $primary-color;
  border-radius: 8px;
  color: white;
  background-color: $primary-color;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  flex-basis: calc(50% - 8px) !important;
  
  &:disabled {
    border: solid 1px $disabled-color;
    background-color: $disabled-color;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
}

.leader-board-button {
  flex: 1;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; 
  border: solid 1px $primary-color;
  border-radius: 8px;
  color: white;
  background-color: $primary-color;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  
  &:disabled {
    border: solid 1px $disabled-color;
    background-color: $disabled-color;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
}

// Leaderboard Card
.leaderboard-card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 20px;
  padding: 18px 56px;
  
  @media (max-width: 768px) {
    padding: 18px 20px;
  }
}

.custom-mt-14 {
  margin-top: 14px;
}

// Leaderboard Header
.leaderboard-header {
  @include flex-between;
  margin-bottom: 24px;
}

// View and Filter Controls
.view-toggles, .filter-controls {
  display: flex;
  gap: 8px;
}

.view-toggle-btn, .filter-btn {
  @include flex-center;
  background-color: $lighter-bg;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #e0e0e0;
  }
}

.filter-btn {
  &.active {
    border: 1px solid green;
    background-color: white;
  }
}

// Search Component
.search-wrapper {
  position: relative;
  flex: 1;
  max-width: 600px;
  margin: 0 15px;
  
  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .search-input {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border: 1px solid $border-light;
    border-radius: 4px;
    font-size: 14px;
    
    &:focus {
      border: 1px solid $primary-color;
    }
  }
  
  .search-count {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: $text-muted;
  }

}

.search-full-width {
  max-width: none !important;

}


.leaderboard-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
}

.leaderboard-table  th {
  white-space:nowrap;
  text-align: inherit;
  display: table-cell;
  vertical-align: inherit;
  padding: 0px 15px;
  unicode-bidi:isolate
}

.leaderboard-row {
  height: 60px;
  border-radius: 8px;
  margin-bottom: 16px;
  border: solid 1px red;
  align-items: center;
}

.leaderboard-cell {
  padding: 15px;
  background-color: $light-bg;
  flex-grow: 1;
  border: 2px solid transparent;
  
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

// Current User Highlighting
.current-user-row {
  border-color: $secondary-color;
  
  .leaderboard-cell {
    border: 2px solid $secondary-color;
    border-left: transparent;
    border-right: transparent;
    background: #EADCFB3D;
    
    &:first-child {
      border-left: 2px solid $secondary-color;
    }
    
    &:last-child {
      border-right: 2px solid $secondary-color;
    }
  }
}

// Position Cell Styling
.position-cell {
  display: flex;
  align-items: center;
  cursor: pointer;

  
  .trend-icon {
    margin-right: 8px;
  }
  
  .trend-up {
    color: $success-color;
  }
  
  .trend-down {
    color: $danger-color;
  }
  
  .trend-neutral {
    color: $text-muted;
  }
  
  .position-medal {
    margin-right: 8px;
  }
}

// Full Name Cell Styling
.fullname-cell {
  display: flex;
  align-items: center;
  cursor: pointer;

  
  .profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      width: 100px;
      height: 100px;
    }
  }
}

// Score Styling
.score-wrapper {
  // display: flex;
  // justify-content: center;
  cursor: pointer;

  
  .score-circle {
    position: relative;
    width: 60px;
    height: 60px;
    
    .score-circle-bg {
      fill: none;
      stroke: #e6e6e6;
      stroke-width: 5;
    }
    
    .score-circle-progress {
      fill: none;
      stroke: #197D21;
      stroke-width: 5;
    }
    
    .score-value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
    }
  }
}

// Course Cell Styling
.course-cell {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  
  .course-main {
    font-weight: 500;
  }
  
  .course-sub {
    font-size: 14px;
    color: $text-muted;
  }
}

// Pagination
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  
  .pagination-btn {
    background-color: transparent;
    border: none;
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
    cursor: pointer;
    
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    
    &.pagination-btn-active {
      color: #000;
      font-weight: 600;
    }
  }
  
  .pagination-ellipsis {
    margin: 0 8px;
  }
}

// Loading and Empty States
.loading-container {
  @include flex-center;
  height: 300px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  text-align: center;
  
  .empty-state-message {
    margin-top: 20px;
    font-weight: 500;
    color: $text-muted;
  }
}

// Category Dropdown
.category-dropdown {
    background-color: white;
    border: 1px solid #DDE3FFAD;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 16px;
    padding: 24px;
    width: 265px;
    
    h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      color: #062324;
      margin-top: 0;
      margin-bottom: 16px;
    }
    
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    button {
      width: 188px;
    }
    
    .category-item {
      cursor: pointer;
      font-size: 14px;
      color: #485C5C;
      font-weight: 400;
      border-radius: 8px;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
    }
    
    .selected-tag {
    //   background-color: #6B7FD714;
    background-color: #6B7FD714;
      color: #042A2B;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 1000px;
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 500;
      
      .check-icon {
        width: 12px;
        height: 12px;
      }
    }
    
    
    // .result-button {
    //   width: 100%;
    //   padding: 14px 0;
    //   border: none;
    //   border-radius: 8px;
    //   color: white;
    //   background-color: #ccc;
    //   margin-top: 20px;
    //   font-size: 14px;
    //   line-height: 16px;
    //   font-weight: 700;
    //   cursor: pointer;
    //   transition: background-color 0.2s;
      
    //   &.active {
    //     background-color: #6B7FD7;
    //   }
    // }
  }

// .active-filter-button {
//   @include button-base;
//   width: 188px;
//   padding: 14px 51px;
//   border: solid 1px $primary-color;
//   border-radius: 8px;
//   color: white;
//   background-color: $primary-color;
//   margin-top: 12px;
// }

.active-filter-button {
    width: 100%;
    height: 44px;
    padding: 14px 51px;
    border: solid 1px #26A65B;
    border-radius: 8px;
    color: white;
    background-color: #26A65B;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
}
// Filter Modal
.filter-modal {
  background-color: white;
  border: 1px solid $border-light-alpha;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 16px;
  padding: 24px;
  width: 423px;
  
  &-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    h6 {
      color: $text-dark;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.filter-section {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  
  label {
    font-weight: bold;
    color: $text-dark;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    margin: 0;
  }
  
  select, input {
    padding: 8px;
    border: 1px solid $disabled-color;
    border-radius: 4px;
    border: 1px solid red;
  }
}

.filter-actions {
  display: flex;
  gap: 16px;
  width: 100%;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.reset-filter {
  flex-basis: calc(50% - 8px) !important;
  padding: 14px 0;
  border: none;
  cursor: pointer;
  color: rgba($text-dark, 0.56);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  flex: 1;
  background: transparent;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
  
  // Full width on mobile
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}

.show-result {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #36d7b7;
  color: white;
  text-align: left;
}

.score-range-container {
  display: flex;
  gap: 16px;
  flex-basis: calc(50% - 8px) !important;


  input {
        height: 48px;
        padding: 8px;
        border: 1px solid #BCC1DE;
        border-radius: 4px;
        flex: 1;

        &:focus {
          border: 1px solid #7117E5
        }
      }
      
}
.filter-modal {
  opacity: 1;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}
.filter-modal-unavailable {
  opacity: 1;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding: 24px;
  padding-bottom: 40px;
  background-color: #fff;
  border-radius: 16px;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
  h4 {
    color: #062324;
    font-weight: 700;
    font-size: 20px;
  }
  p {
    color: #2C3E4B;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
}

.filter-modal-unavailable-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  cursor: pointer;
}

.overlay {
  opacity: 0;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }
}

.public-leaderboard-header {
  margin-top: 100px;
  margin-bottom: 32px;
}